.section {
    margin: 0 0 20px 0;
}

@media (max-width: 576px) {
    .ant-card {
        margin-bottom: calc(73px + 4.2vw);
    }

    .ant-card-body {
        padding-bottom: 0;
    }

    .card-footer {
        position: fixed;
        bottom: 0;
        left: 0;
        margin: 0;
        padding: 16px;
        width: 100%;
    }
}
